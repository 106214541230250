import PropTypes from 'prop-types';
import React from 'react';

class SigninCallbackComponent extends React.Component {
    static propTypes = {
        signinType: PropTypes.string,
        // the content to render
        children: PropTypes.element.isRequired,

        // the userManager
        userManager: PropTypes.object.isRequired,

        // a function invoked when the callback succeeds
        successCallback: PropTypes.func.isRequired,

        // a function invoked when the callback fails
        errorCallback: PropTypes.func
    };

    componentDidMount() {
        if (this.props.signinType === 'redirect') {
            this.props.userManager.signinRedirectCallback()
                .then((user) => this.onSuccess(user))
                .catch((error) => this.onError(error));

        } else {
            this.props.userManager.signinPopupCallback()
                .then((user) => this.onSuccess(user))
                .catch((error) => this.onError(error));

        }

    }

    onSuccess = (user) => {
        this.props.successCallback(user);
    };

    onError = (error) => {
        if (this.props.errorCallback) {
            this.props.errorCallback(error);
        } else {
            throw new Error(`Error handling signIn callback: ${error.message}`);
        }
    };

    render() {
        return React.Children.only(this.props.children);
    }
}

export default SigninCallbackComponent;