import Oidc, { UserManager, WebStorageStateStore } from 'oidc-client';

let settings = {
    authority: "https://localhost:5003",
    client_id: "AM_React",
    redirect_uri: `${window.location.origin}/callback`,
    popup_redirect_uri: `${window.location.origin}/callback`,
    silent_redirect_uri: `${window.location.origin}/silent`,
    response_type: 'code',
    scope: "openid profile email platform.asset_management",
    revokeAccessTokenOnSignout: true,
    post_logout_redirect_uri: `${window.location.origin}`,
    loadUserInfo: true,
    filterProtocolClaims: true,
    popupWindowFeatures: "location=no,toolbar=no,width=500,height=500,left=100,top=100",
    popupWindowTarget: "_blank",
    automaticSilentRenew: true,
    userStore: new WebStorageStateStore({ store: window.localStorage })

}

const registerEvents = (userMgr, callback) => {
    const { onUserLoaded, onSilentRenewError, onAccessTokenExpired, onUserUnloaded, onAccessTokenExpiring, onUserSignedOut, onUserSessionChanged } = callback;

    userMgr.events.addUserLoaded(user => {
        localStorage.setItem('OIDCUSER', JSON.stringify(user));
        if (onUserLoaded && (typeof onUserLoaded === "function"))
            onUserLoaded(user);
    });

    userMgr.events.addAccessTokenExpiring(() => {
        if (onAccessTokenExpiring && (typeof onAccessTokenExpiring === "function"))
            onAccessTokenExpiring();
    });

    userMgr.events.addUserUnloaded(() => {
         localStorage.removeItem('OIDCUSER');
        if (onUserUnloaded && (typeof onUserUnloaded === "function"))
            onUserUnloaded();
    })

    userMgr.events.addAccessTokenExpired(() => {
        if (onAccessTokenExpired && (typeof onAccessTokenExpired === "function"))
            onAccessTokenExpired();
    });

    userMgr.events.addSilentRenewError((error) => {
        if (onSilentRenewError && (typeof onSilentRenewError === "function"))
            onSilentRenewError(error);
    });

    userMgr.events.addUserSignedOut(() => {
        // Call removeUser to stop the session Monitor, so that this event will not be raised and handled again.
        userMgr.removeUser().then(() => {
            if (onUserSignedOut && (typeof onUserSignedOut === "function"))
                onUserSignedOut();
        });
    });

    userMgr.events.addUserSessionChanged(() => {
        if (onUserSessionChanged && (typeof onUserSessionChanged === 'function')) {
            onUserSessionChanged();
        }
    })
}

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.INFO;

let instance = null;
export default class Authentication {
    constructor(options) {
        if (!instance) {
            if (options && options.config)
                settings = { ...settings, ...options.config };
            this.clearStaleUser();
            this.userMgr = new UserManager(settings);
            this.signinType = options.signinType;
            let callback = {};
            if (options && options.registerEvents)
                callback = options.registerEvents
            registerEvents(this.userMgr, callback);
            instance = this;
        }
        return instance;
    }

    static ShareInstance(options) {
        let singleton = new Authentication(options);
        return singleton;
    }

    clearStaleUser = () => {
        const oidcUserKey = `oidc.user:${settings.authority}:${settings.client_id}`;
        const user = JSON.parse(localStorage.getItem(oidcUserKey));
        if (user && user.expires_at) {
            if (new Date().getTime() > user.expires_at * 1000) {
                localStorage.removeItem(oidcUserKey);
            }
        }
    }

    // Get the user who is logged in
    getUser() {
        return new Promise((resolve, reject) => {
            this.userMgr.getUser().then((user) => {
                if (user == null) {
                    return resolve(null)
                } else {
                    return resolve(user)
                }
            }).catch((err) => {
                return reject(err)
            });
        })
    }


    // Redirect of the current window to the authorization endpoint.
    signIn() {
        if (this.signinType === 'redirect') {
            this.userMgr.signinRedirect().catch((err) => {
                console.log(err)
            });
        } else {
            this.userMgr.signinPopup().catch((err) => {
                console.log(err)
            })
        }

    }

    // Determine if the user has logged in and is legal
    async checkUserAuthorized() {
        const user = await this.getUser().catch(() => { return false; });
        if (user && !user.expired) {
            const sessionState = await this.userMgr.querySessionStatus().catch(() => { return false; });
            if (sessionState) {
                return true;
            }
        }
        return false;
    }

    // Check if there is any user logged in
    checkSignedIn() {
        return new Promise((resolve, reject) => {
            this.userMgr.querySessionStatus().then((res) => {
                if (res) {
                    this.signIn();
                    return resolve(true)
                } else {
                    return resolve(false)
                }
            }).catch((err) => {
                return reject(err)
            });

        })


    }

    // Redirect of the current window to the end session endpoint
    signOut() {
        return new Promise((resolve, reject) => {
            this.userMgr.signoutRedirect().then((resp) => {
                this.userMgr.clearStaleState();
                return resolve(true)
            }).catch((err) => {
                return reject(err)
            })
        })
    }

    signinSilent() {
        this.userMgr.signinSilent().then((resp) => {
        }).catch((err) => {
        })
    }

}



