(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("oidc-client"), require("prop-types"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["oidc-client", "prop-types", "react"], factory);
	else if(typeof exports === 'object')
		exports["Authentication"] = factory(require("oidc-client"), require("prop-types"), require("react"));
	else
		root["Authentication"] = factory(root["_"], root["prop-types"], root["react"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__106__, __WEBPACK_EXTERNAL_MODULE__127__, __WEBPACK_EXTERNAL_MODULE__128__) {
return 